exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-project-mdx": () => import("./../../../src/pages/aboutProject.mdx" /* webpackChunkName: "component---src-pages-about-project-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-project-docs-mdx": () => import("./../../../src/pages/projectDocs.mdx" /* webpackChunkName: "component---src-pages-project-docs-mdx" */),
  "component---src-pages-support-us-mdx": () => import("./../../../src/pages/supportUs.mdx" /* webpackChunkName: "component---src-pages-support-us-mdx" */),
  "component---src-pages-thank-you-mdx": () => import("./../../../src/pages/thankYou.mdx" /* webpackChunkName: "component---src-pages-thank-you-mdx" */),
  "component---src-templates-markdown-template-jsx-content-file-path-src-pages-about-project-mdx": () => import("./../../../src/templates/markdownTemplate.jsx?__contentFilePath=/home/runner/work/disneyapi.dev/disneyapi.dev/src/pages/aboutProject.mdx" /* webpackChunkName: "component---src-templates-markdown-template-jsx-content-file-path-src-pages-about-project-mdx" */),
  "component---src-templates-markdown-template-jsx-content-file-path-src-pages-project-docs-mdx": () => import("./../../../src/templates/markdownTemplate.jsx?__contentFilePath=/home/runner/work/disneyapi.dev/disneyapi.dev/src/pages/projectDocs.mdx" /* webpackChunkName: "component---src-templates-markdown-template-jsx-content-file-path-src-pages-project-docs-mdx" */),
  "component---src-templates-markdown-template-jsx-content-file-path-src-pages-support-us-mdx": () => import("./../../../src/templates/markdownTemplate.jsx?__contentFilePath=/home/runner/work/disneyapi.dev/disneyapi.dev/src/pages/supportUs.mdx" /* webpackChunkName: "component---src-templates-markdown-template-jsx-content-file-path-src-pages-support-us-mdx" */),
  "component---src-templates-markdown-template-jsx-content-file-path-src-pages-thank-you-mdx": () => import("./../../../src/templates/markdownTemplate.jsx?__contentFilePath=/home/runner/work/disneyapi.dev/disneyapi.dev/src/pages/thankYou.mdx" /* webpackChunkName: "component---src-templates-markdown-template-jsx-content-file-path-src-pages-thank-you-mdx" */)
}

